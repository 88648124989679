import(/* webpackMode: "eager" */ "/vercel/path0/apps/bridge/public/images/data-availability.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/bridge/src/app/(marketing)/sections/Community.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Description"] */ "/vercel/path0/apps/bridge/src/app/(marketing)/sections/Description.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/apps/bridge/src/app/(marketing)/sections/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Metrics"] */ "/vercel/path0/apps/bridge/src/app/(marketing)/sections/Metrics.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
